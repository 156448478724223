<template>
	<statistics-layout :is-loading="isLoading">
		<div v-if="submissions.length && !isLoading">
			<data-table v-if="mq.small" :is-compact="true" :is-hoverable="false" :is-full-width="false">
				<thead>
					<tr>
						<th>{{ translate('consignerStudy') }}</th>
						<th>{{ translate('consigner') }}</th>
						<th>Study Date</th>
						<th>Dam</th>
						<th>Sire</th>
						<th>Chip Id</th>
						<th>Has Images</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="submission in submissions"
						:key="submission.lotNumber"
						:class="{ 'no-images': !submission.hasImages }"
					>
						<td>{{ submission.lotNumber }}</td>
						<td>{{ submission.consignerName }}</td>
						<td>{{ submission.studyDateTime | localizeDate({ forceUTC: false }) }}</td>
						<td>{{ submission.dam }}</td>
						<td>{{ submission.sire }}</td>
						<td>{{ submission.chipId }}</td>
						<td>
							<svg-icon
								:icon="submission.hasImages ? 'check-circle' : 'close'"
								:class="{ 'has-images': submission.hasImages }"
								fixed
							/>
						</td>
					</tr>
				</tbody>
			</data-table>
			<div v-if="!mq.small">
				<ast-card
					v-for="submission in submissions"
					:key="submission.submissionId"
					class="submission-card"
					:class="{ 'no-images': !submission.hasImages }"
				>
					<div class="row">
						<div class="col-sm-12">
							<ul>
								<li>
									<label>{{ translate('consignerStudy') }}:</label>
									{{ submission.lotNumber }}
								</li>
								<li>
									<label>Consigner:</label>
									{{ submission.consignerName }}
								</li>
								<li>
									<label>Study Date:</label>
									{{ submission.studyDateTime | localizeDate({ forceUTC: false }) }}
								</li>
							</ul>
						</div>
						<div class="col-sm-12">
							<ul>
								<li>
									<label>Dam:</label>
									{{ submission.dam }}
								</li>
								<li>
									<label>Sire:</label>
									{{ submission.sire }}
								</li>
								<li>
									<label>Chip Id:</label>
									{{ submission.chipId }}
								</li>
							</ul>
						</div>
						<div class="col-sm-12">
							<ul>
								<li>
									<label>Images:</label>
									<span v-if="submission.hasImages">
										Yes
										<svg-icon icon="check-circle" />
									</span>
									<span v-else>
										No
										<svg-icon icon="close" />
									</span>
								</li>
							</ul>
						</div>
					</div>
				</ast-card>
			</div>
		</div>
		<p v-if="!submissions.length && !isLoading" class="placeholder">
			No submissions found.
		</p>
	</statistics-layout>
</template>

<script>
import StatisticsLayout from '@router/layouts/Statistics'
import AstCard from '@components/Card'
import DataTable from '@components/DataTable'
export default {
	components: {
		StatisticsLayout,
		AstCard,
		DataTable,
	},
	props: {
		clinicCode: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			submissions: [],
			isLoading: true,
		}
	},
	watch: {
		'$route.query.saleId'() {
			this.loadSubmissions()
		},
	},
	mounted() {
		this.loadSubmissions()
	},
	methods: {
		async loadSubmissions() {
			this.isLoading = true
			try {
				let params = {
					clinicCode: this.clinicCode,
					saleId: this.$route.query.saleId,
					securityToken: this.id,
				}
				const { data } = await this.$api.statistics.getSubmissions(params)
				this.submissions = data
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@media print {
	table {
		max-width: 100%;
		page-break-inside: avoid;
		th,
		td {
			padding: 0.25em 0.5em !important;
			font-size: 0.6em !important;
		}
	}
	.no-images {
		font-style: italic;
	}
}

@media screen {
	.icon {
		color: #a44;
		&.has-images {
			color: #4a4;
		}
	}
	.no-images td {
		background: #fefcea !important;
	}
	.no-images:nth-of-type(odd) td {
		background: #fcfade !important;
	}
}
</style>
