<template>
	<section class="statistics-view">
		<slot></slot>

		<transition name="fade">
			<div v-show="isLoading" class="loading">
				<svg-icon icon="spinner" pulse />
			</div>
		</transition>
	</section>
</template>

<script>
export default {
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		document.body.style.overflow = 'auto'
		document.getElementById('app').style.overflow = 'auto'
	},
	destroyed() {
		document.body.style.overflow = 'hidden'
		document.getElementById('app').style.overflow = 'hidden'
	},
}
</script>

<style lang="scss">
.statistics-view {
	.loading {
		background: #fff;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: center;
		.icon {
			font-size: 4em;
			opacity: 0.5;
		}
	}
	a {
		font-weight: 500;
		text-decoration: underline;
	}
	input {
		box-shadow: none;
		height: 2.25em;
		padding: 0.5em;
		outline: none !important;
	}
	button:not(.close):not(.btn-close):not(.vs__deselect) {
		position: relative;
		border-radius: 3px;
		margin: 0 0 1em 0;
		padding: 0.5em 0.75em;
		border: 0;
		background: #e8e8e8;
		cursor: pointer;
		&:hover {
			background: #ddd;
		}
		&.is-loading {
			color: rgba(0, 0, 0, 0);
			.spinner {
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				justify-content: center;
				color: #777 !important;
				text-align: center;
			}
		}
		svg {
			top: 0.125em;
			position: relative;
		}
		& + button {
			margin-left: 2px;
		}
	}
	.placeholder {
		margin: 0.5em 0;
		font-size: 1.2em;
		font-style: italic;
		opacity: 0.75;
	}
}
table {
	tr th {
		border-bottom: 0;
		padding-bottom: 0;
	}
	.text-right {
		text-align: right !important;
	}
	th,
	td {
		font-size: 0.9em;
	}
	td {
		white-space: nowrap;
	}
}
.v-select {
	min-width: 160px; // match min-width of actual dropdown menu
	max-width: 500px;
	a {
		text-decoration: none;
	}
}
.card {
	h3,
	li {
		margin: 0.25em;
	}
	h3 {
		font-size: 1em;
	}
	ul {
		list-style: none;
		margin-bottom: 0.5em;
	}
	li {
		font-weight: 300;
		label {
			font-weight: 500;
			padding-right: 0.5em;
		}
	}
}
</style>
